<template>
    <div :class="'tgju-widgets-block ' + col " ref="top">
        <!-- فرم ارسال توییت -->
        <PostSend v-if="user && sendbox == 'enabled' " :newPost="newPost" ref="post-postSend"></PostSend>
        <slot v-if="widgetLoad">
            <div v-if="widgetLoad" class="tgju-widget light mt-3 mb-2 post">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>
        <slot v-else> 
            <!-- پست -->
            <div class="tgju-widget tgju-widgets-row-boxed light post mt-20" :class="(paginateLoad ? 'loading-active comments-loading' : '')">
                <div class="tgju-widgets-sort-row">
                    <span class="float-left pl-1">
                        {{ posts.total }} نظر
                        <a v-if="!user && auth == 'show'" @click="open_login_modal" class="tgju-btn tgju-btn-primary comment-login-btn">
                            <i class="fa fa-sign-in"></i> ورود - ثبت نام
                        </a>
                    </span>
                    <div class="float-right pr-1" style="margin-top: -8px;">
                        <a @click="sort('best')" class="pl-3">
                            <i class="sort-icon-i uil uil-thumbs-up"></i>
                            <div class="sort-title">محبوب‌ترین‌ها</div>
                        </a>
                        <a @click="sort('old')" class="pl-3">
                            <i class="sort-icon-i uil uil-sort-amount-up"></i>
                            <div class="sort-title">قدیمی‌ترین‌ها</div>
                        </a>
                        <a @click="sort('new')" class="pl-3">
                            <i class="sort-icon-i uil uil-sort-amount-down"></i>
                            <div class="sort-title">جدیدترین‌ها</div>
                        </a>
                    </div>
                </div>
                <!-- نمایش پیام در بالای کامنت ها -->
                <div class="post-row" v-if="text_content!=''">
                    <p v-if="text_content!=''" style="margin: 15px;color: brown;text-align: justify;border-radius: 4px;border: 1px solid #bf8888;padding: 10px;direction: rtl;background: #ffeded;">{{text_content}}</p>
                </div>
                <div v-for="post in posts.data" :key="post.id">
                    <div class="post-row">
                        <PostItem :post="post" :technicals="technicals" :subject="subject" :title="title"></PostItem>
                    </div>
                </div>
            </div>
            <template v-if="posts.data.length">
                <div class="tgju-widget light border-0 post mb-2">
                    <div v-if="posts.last_page > 1" class="p-3 d-flex justify-content-center pagination-box-items">
                        <Paginate :page-count="posts.last_page" :click-handler="loadData" :prev-text="'قبل'" :next-text="'بعد'" :container-class="'pagination'"></Paginate>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="tgju-widget light post mb-2 mt-3">
                    <div class="no-content d-flex justify-content-center">
                        <div class="text-center p-2">
                            <div v-if="isMyProfile" class="not-found-items">
                                <i class="uil uil-comment-alt-slash"></i><br>
                                {{ message }}
                                <br>
                                اولین دیدگاه را ثبت کنید.
                            </div>
                            <div v-else class="not-found-items">
                                <i class="uil uil-comment-alt-slash"></i><br>
                                {{ message }}.
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </slot>
    </div>
    </template>
    
    <style lang="scss">
        .textarea-post {
            padding: 10px;
    
            &:focus {
                outline: 0;
            }
    
            .emoji {
                width: 20px;
            }
        }
    
        .post .no-content {
            padding: 20px 15px;
            font-size: 13px;
        }
    
        #popper-button {
            position: relative;
            top: -4px;
        }
    
        #btn-emoji-default {
            margin: 0 !important;
            padding: 0 !important;
            width: 30px !important;
            height: 22px !important;
        }
    
        #btn-emoji-default>div>img.emoji {
            height: 20px !important;
            width: 20px !important;
        }
    
        #emoji-container>#emoji-popup img.emoji {
            height: 21px !important;
            width: 21px !important;
        }
    
        #emoji-container>#emoji-popup #emoji-popover-search>#search-header>span {
            padding: 10px 10px !important;
        }
    
        #emoji-container>#emoji-popup #emoji-popover-search>#search-header>input {
            font-size: 14px !important;
        }
    
        #popper-container {
            z-index: 9999 !important;
        }
    
        #popper-container #arrow {
            display: none !important;
        }
    
        #emoji-container>#emoji-popup .emoji-popover-inner {
            margin-right: -4px !important;
        }
    
        #emoji-container>#emoji-popup .emoji-popover-inner>div>.emoji-list>span {
            font-size: 8px !important;
        }
    
        .textarea-post .emoji {
            margin-right: 4px;
        }
    
        .profile-post-content .emoji {
            width: 17px;
            margin-right: 3px;
        }
    
        .profile-post-content .post-img {
            max-width: 100%;
            margin-top: 20px;
        }
    
        .left-menu-dropdown {
            display: block !important;
        }
    
        .user-block-reshare {
            margin: 20px;
            display: block;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.03) !important;
            border-radius: 6px !important;
            border: 1px solid #dee2e6;
    
            .tgju-widget-content {
                padding: 6.4px 20px 10px;
            }
    
            .user-block-avatar {
                width: 35px;
                height: 35px;
            }
    
            .post-btn-row {
                display: none;
            }
    
            .reshare-label {
                text-align: center;
                color: #676767;
                white-space: nowrap;
                display: flex;
                align-items: center;
                padding: 9px 15px;
                justify-content: start;
                border-bottom: 1px solid #ccc;
    
                i {
                    font-size: 18px;
                    line-height: 0;
                    position: relative;
                    margin-left: 5px;
                    top: 1px;
                }
    
                .text {
                    font-size: 11px;
                }
            }
        }
    
        .emoji-mart {
            z-index: 99999999;
            position: absolute;
            left: 45px;
        }
    
        .vue-popover-theme {
            z-index: 999;
    
            .tooltip-inner {
                border-radius: 5px;
                padding: 6px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 12px;
                background: rgb(249, 249, 249);
            }
    
            .mention-item {
                padding: 3px 7px;
                cursor: pointer;
                border-radius: 5px;
    
                &.mention-selected {
                    background: rgb(0, 123, 255);
                    color: #fff;
                }
            }
        }
    </style>
    
    <script>
    // این کامپوننت اصلی برای بخش نظرات می باشد که دارای اجزای زیادی از جمله کامپوننت ارسال نظر و .. میباشد
    import PostSend from '@/components/TgjuPosts/PostSend.vue';
    import PostItem from '@/components/TgjuPosts/PostItem.vue';
    import Paginate from "vuejs-paginate";
    import Centrifuge from "centrifuge";
    
    export default {
        name: 'TgjuPost',
        components: {
            PostSend,
            PostItem,
            Paginate,
        },
        props: ['subject', 'col', 'type', 'auth', 'username', 'sendbox', 'title', 'message'],
        data: function () {
            return {
                newPost: {
                    content: '',
                    type: 'tgju-post',
                    parent_id: 0,
                    subject: this.subject,
                    attachments: [],
                },
                technicals : null,
                posts: {
                    length: undefined,
                    current_page: undefined,
                    from: undefined,
                    last_page: undefined,
                    next_page_url: undefined,
                    per_page: undefined,
                    prev_page_url: undefined,
                    to: undefined,
                    total: undefined,
                    data: {
                        length: undefined,
                    }
                },
                user: this.$helpers.userSync(),
                sort_value: 'new',
                widgetLoad: true,
                paginateLoad: false,
                firstTime: true,
                text_content:'',
            }
        },
        mounted() {
            var that = this;
            this.loadData();
            this.missedPosts();
            setInterval(function() {
                if (!that.user && window.localStorage.access_token) {
                    location.reload();
                }
            }, 1000);
        },
        methods: {
            // این متد وظیفه دریافت لیست نظرات کاربر را با توجه با داده ها مورد نظر بر میگرداند به صورت صفحه بندی شده
            loadData(page) {
                let api_data = {
                    subject: this.subject,
                    username: this.username == "" ? 'everybody' : this.username,
                    sort: this.sort_value,
                    page: page ? page : 1,
                };
    
                // در صورتی که کامپوننت در ویجت ساز در حال استفاده بود
                if (this.type != 'widget') {
                    $("body").append(`
    
    <style>
    
    footer , header , .list-box-index , .index-row-items , bar-menu-dashboard-box
    
    .bar-menu , .footer-quickview , .mobile-hide , .bottom-left-ad , .bottom-right-ad , .bar-menu-dashboard-box {
    
    display: none !important;
    
    }
    
    body {
    
    margin: 0px !important;
    
    padding: 0px !important;
    
    background: white !important;
    
    }
    
    .widgets {
    
    border: none !important;
    
    }
    
    </style>
    
    `);
                }
    
                this.paginateLoad = true;
    
                // ارسال درخواست جهت دریافت اطلاعات
                this.$helpers.makeRequest('GET', '/tgju-post/list', api_data).then(api_response => {
                    this.$helpers.api_alert(api_response);
    
                    if (api_response.status == 200) {
                        this.text_content=api_response.data.response.text_notification.text_content;
                        let response = api_response.data.response;
                        this.posts = response.posts;
                        this.technicals = response.technicals;
                        this.widgetLoad = false;
                        if (!this.firstTime) {
                            this.$refs.top.scrollIntoView(true);
                        }
                        this.firstTime = false;
                        this.paginateLoad = false;
                    }
                });
            },
            setCaretToEnd() {
                let postSendComponent = this.$refs['post-postSend'];
                postSendComponent.setCaretToEnd(postSendComponent.$refs['textarea-post']);
            },
            // اسکرول کردن به دایو یا لی اوت مورد نظر
            scrollTo(container) {
                let postSendComponent = this.$refs['post-postSend'];
                postSendComponent.scrollTo(container);
            },
            // این متد جهت فیلتر سازی و مرتب سازی بر اساس نیاز کاربر می باشد 
            // قدیمی ، جدید ، محبوبت ترین
            sort(value) {
                this.sort_value = value;
                this.loadData();
            },
            // این متد برای باز کردن بخش لاگین در بخش نظرات سایت تی جی جی یو استفاده میشود
            open_login_modal() {
                if ($('#auth-modal').length) {
                    $('#auth-modal').toggle();
                } else {
                    window.open(process.env.VUE_APP_URL + '/auth/login?popup=true', 'targetWindow',
                                                                `toolbar=no,
                                                                    location=no,
                                                                    status=no,
                                                                    menubar=no,
                                                                    scrollbars=yes,
                                                                    resizable=yes,
                                                                    width=420,
                                                                    height=560`);
    
                    // window.Auth.set_api_domain(process.env.VUE_APP_API_URL);
                    // window.Auth.load();
                }
            },
            getMissedPosts() {
                let allPosts = false;
                var xmlHttp = new XMLHttpRequest();
                xmlHttp.open( "GET", this.$helpers.api_url() + '/posts/' + this.subject + '.json?rev=' + Math.random(), false );
                xmlHttp.send();
                allPosts = JSON.parse(xmlHttp.responseText);
    
                setTimeout( () => {
                    if (!allPosts) {
                        xmlHttp.abort();
                    }
                }, 10000)
    
                if (allPosts[0].created_at > this.posts.data[0].created_at) {
                    this.posts.data = allPosts;
                }
            },
            // درخواست به فایل اخرین پیام
            lastPostRequest() {
                var xmlHttp = new XMLHttpRequest();
                xmlHttp.open( "GET", this.$helpers.api_url() + '/posts/last-post.json?rev=' + Math.random(), false );
                xmlHttp.send();
                let lastPost = JSON.parse(xmlHttp.responseText);
    
                if (lastPost[this.subject].created_at > this.posts.data[0].created_at) {
                    // تابع درخواست زدن به پست های اخیر
                    this.getMissedPosts()
                }
            },
            // به فایل حاوی پیام اخر درخواست ارسال میکند
            missedPosts() {
                let that = this;
                if (that.$store.state.set == 0) {
                    this.interval = setInterval( () => {
                        that.$store.state.set = 1;
                        if (this.posts.current_page == 1) {
                            if (this.$route.path == '/forums' || this.$route.path == '/comment') {
                                this.lastPostRequest();
                            }
                        }
                    }, 30000);
                }
            }
        },
        destroyed() {
            clearInterval(this.interval);
            this.$store.state.set = 0;
        },
        computed: {
            // مشخص کردن هدر پروفایل خودمان از کاربران دیگر
            isMyProfile() {
                return this.$store.state.isMyProfile;
            },
            // گرفتن ایدی پیام جدید دریافت شده از وب سوکت و ذخیره شده در استور
            getPostId () {
                return this.$store.state.globalCentrifugeChat;
            }
        },
        watch: {
            getPostId (newData, oldData) {
                let that = this;
    
                // بررسی اطلاعات دریافتی از وب‌سوکت
                if (newData.type == 'like') {
                    that.posts.data.filter( (post) => {
                        if (post.id ==  newData.message_id) {
                            post.likes = newData.like;
                        } else if (post.comments != 0) {
                            post.comments.data.filter( (comment) => {
                                if (comment.id == newData.message_id) {
                                    comment.likes = newData.like;
                                }
                            });
                        }
                    });
                }else if (newData.type == 'delete') {
                    that.posts.data.filter( (post) => {
                        if (post.parent_id == 0) {
                            if (post.id == newData.message_id) {
                                that.posts.data.splice(that.posts.data.indexOf(post), 1);
                            }
                        }
                    });
                }else {
                    if (newData.message_data.updated_at > that.posts.data[0].created_at && that.subject == newData.message_data.discussion) {
                        if (newData.message_data.parent_id > 0) {
                            that.posts.data.filter( (post) => {
                                if (post.id ==  newData.message_data.parent_id) {
                                    if (post.comments != 0) {
                                        post.comments.data = [newData.message_data, ...post.comments.data];
                                        post.comments_count++;
                                        post.comments.total++;
                                        if (post.comments.total > 4) {
                                            post.comments.data.pop();
                                            let lastPage = post.comments.total / 4;
                                            if (Number.isInteger(lastPage)) {
                                                post.comments.last_page = lastPage;
                                            } else {
                                                post.comments.last_page = Math.ceil(lastPage);
                                            }
                                            post.comments.next_page_url = "http://localhost:9091/v1/tgju-post/comments/?page=" + post.comments.last_page;
                                        }
                                    } else {
                                        let comments = {
                                            current_page: 1,
                                            form: 1,
                                            last_page: 1,
                                            next_page_url: null,
                                            per_page: 4,
                                            prev_page_url: null,
                                            to: 1,
                                            total: 1,
                                            data: [newData.message_data],
                                        };
                                        post.comments = comments;
                                        post.comments_count = 1;
                                    }
                                }
                            });
                        } else {
                            if (that.posts.data.length > 9) {
                                that.posts.data.pop();
                            }
                            that.posts.data = [newData.message_data, ...that.posts.data];
                        }
                    }
                }
            },
        }
    }
    </script>
    