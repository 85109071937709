<template>
<div class="row tgju-widgets-row dashboard-widget-profile widget-border-fix widget-padding" style="padding:0px !important;">
    <div class="col-12 col-md-12 col-xl-6">
        <div :class="'row tgju-widgets-row mr-1 mb-3 profile-post-widgets '+ market_tab +''">
            <div :class="'widget-select widget-select-boxed w-100w '+ discussion_active.text +''">
                <v-select2 v-model="discussion_active" @input="componentKey += 1,setMarketTab(discussion_active.text)" :clearable="false" label="value" :options="discussions">
                    <i class="uil uil-list-ui-alt"></i>
                </v-select2>
            </div>
            <TgjuPost message="شما در این کانال تاکنون دیدگاهی به ثبت نرسانده‌اید." type="widget" auth="hide" :username="this.$helpers.userInfo().username" sendbox="enabled" :subject="discussion_active.text" col="w100-w mobile-mb-0" :key="componentKey"></TgjuPost>
        </div>
    </div>

    <div class="tgju-widgets-block col-12 col-md-12 col-xl-6 p-0 profile-blocks">
        <div class="row tgju-widgets-row">

            <div class="tgju-widgets-block col-12 col-md-12 col-xl-12 col-xxxl-6">
                <div class="row tgju-widgets-row">
                    <div class="tgju-widgets-block col-12" data-widget-type="news" data-widget-id="4" data-widget-size="col-xl-4" data-widget-options='{"id":"1","count":"8"}'>
                        <LastPosts :username="this.$helpers.userInfo().username"></LastPosts>
                    </div>
                </div>
            </div>
            
            <div class="tgju-widgets-block col-12 col-md-12 col-xl-12 col-xxxl-6">
                <div class="row tgju-widgets-row">
                    <div class="tgju-widgets-block col-12" data-widget-type="news" data-widget-id="4" data-widget-size="col-xl-4" data-widget-options='{"id":"1","count":"8"}'>
                        <LastFollowingTechnicals :username="this.$helpers.userInfo().username"></LastFollowingTechnicals>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
</template>

<script>
// این فایل ویو پروفایل خوده کاربر هستش که کاپونتت هایی داخلی استفاده میشوند
import TgjuPost from '@/components/TgjuPosts/Post.vue'
import LastPosts from '@/components/LastPosts.vue'
import LastFollowingTechnicals from '@/components/LastFollowingTechnicals.vue'
import vSelect2 from 'vue-select'
Vue.component('v-select2', vSelect2);

export default {
    name: 'ProfileView',
    metaInfo: {
        title: 'نمایه پروفایل کاربر',
        titleTemplate: '%s'
    },
    components: {
        TgjuPost,
        LastPosts,
        LastFollowingTechnicals,
    },
    data: function () {
        return {
            market_tab: 'tgju-discussion-18',
            componentKey: 0,
            discussion_active: { text: 'tgju-discussion-18', value: 'بازار طلا' },
            discussions: [
                { text: 'tgju-discussion-18', value: 'بازار طلا' },
                { text: 'tgju-discussion-19', value: 'بازار سکه' },
                { text: 'tgju-discussion-20', value: 'بازار ارز' },
                { text: 'tgju-discussion-global-2', value: 'بازار ارزهای دیجیتال' },
                { text: 'tgju-discussion-28', value: 'بازار بورس' },
                { text: 'tgju-discussion-36', value: 'بازار‌های کالایی' },
                { text: 'tgju-discussion-global-1', value: 'بازار‌های سهام' },
                { text: 'tgju-discussion-37', value: 'بازار نفت و انرژی' },
                { text: 'tgju-discussion-global-3', value: 'بازار مبادلات ارزی' },
                { text: 'tgju-discussion-35', value: 'بازار فلزات' },
            ],
        }
    },
    methods: {
        // این متد برای هندل کردن اکشن تغییر دسته بندی نظرات استفاده میشود
        onChange(event) {
            this.discussion = event.target.value;
            this.componentKey += 1
        },
        // این متد برای تغییر و هایلایت کردند بازار انتخاب شده مورد استفاده قرار میگیرد
        setMarketTab(elm) {
            this.market_tab = elm;
        },
    },
}
</script>
