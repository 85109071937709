<template>
<div>
    <div class="tgju-widget-title p-0 m-0 border-0">
        <div class="user-block-info">
            <router-link :to="{ name: 'ProfileOthers', params: { username: post.user.username } }" class="user-block-avatar hover-popper avatar-u-tag-box" target="_blank">
                <img v-if="post.user && post.user !== 0" :src="$helpers.isJson(post.user.avatar) ? ($helpers.api_url() + $helpers.json_pars(post.user.avatar).name) : 'https://static.tgju.org/views/default/images/tgju-user-avatar.png'">
                <img v-else :src="'https://static.tgju.org/views/default/images/tgju-user-avatar.png'">
                <div class="avatar-u-tag" style="margin-top: 5px;">
                    <span v-if="post.user.tick_mark === 'analyst'" class="user-block-title-tag user-tag-primary">تحلیل‌گر</span>
                    <span v-if="post.user.tick_mark === 'admin'" class="user-block-title-tag user-tag-warning">پشتیبان</span>
                </div>
            </router-link>
            <div class="user-block-data hover-popper">
                <router-link v-if="post.user && post.user !== 0 && !post.name" :to="{ name: 'ProfileOthers', params: { username: post.user.username } }" class="title" target="_blank">
                    <div>
                        {{ post.user.firstname }}  {{ post.user.lastname }} 
                        <!-- <br>
                        <div class="username-tag">@{{ post.user.username }}</div> -->
                    </div>
                    <!-- {{ post.user.firstname }} {{ post.user.lastname }} -->
                    <!-- <span v-tooltip="'سطح کاربر'" class="user-block-title-tag">کاربر‌ عادی</span> -->
                </router-link>
                <span v-else class="title">
                    {{ post.name }} <span class="user-block-title-tag">@{{ post.user.name }}</span>
                </span>
                <div class="user-block-small-items">
                    <router-link v-if="post.user" :to="{ name: 'ProfileOthers', params: { username: post.user.username } }" class="title" target="_blank">
                        <!-- <span class="user-block-small-item hover-link" v-tooltip="''+ post.user.username +'@'">
                            <i class="uil uil-at"></i>
                        </span> -->
                        <div class="username-tag-c">@{{ post.user.username }}</div>
                    </router-link>

                    <div class="user-block-small-item" v-tooltip="'تاریخ ارسال دیدگاه'">
                        <i class="uil uil-clock"></i>
                        <span>ارسال {{ toRelative(post.created_at) }}</span>
                    </div>
                    <div class="user-block-small-item" v-tooltip="'تاریخ ویرایش دیدگاه'" v-if="toRelative(post.created_at) != toRelative(post.modified_at)">
                        <i class="uil uil-clock"></i>
                        <span>ویرایش {{ toRelative(post.modified_at) }}</span>
                    </div>
                </div>
                <!-- <span class="time private">{{ toRelative(post.created_at) }}</span> -->
            </div>
            <div v-if="title" v-tooltip="'اتاق ارسالی'" :class="'badge user-block-badge ' + subject + ($parent.$options.name == 'TgjuPost' && ($parent.user && $parent.user.id != post.user.id) ? ' left-20' : '')"> {{ (title) ? title : '' }}</div>
            <div class="user-block-actions" v-if="$parent.$options.name == 'TgjuPost' && ($parent.user && $parent.user.id == post.user.id) && hiddenTimer(post.created_at, totalTimer)">
                <dropdown-menu v-model="post.showMenu">
                    <a href="#" class="post-btn share dropdown-toggle" @click.prevent="" v-tooltip="'مدیریت'">
                        <i class="uil uil-ellipsis-v" aria-hidden="true"></i>
                    </a>
                    <div slot="dropdown">
                        <a class="dropdown-item" href="#" @click.prevent="goEditPost(post.id)"><i class="uil uil-edit-alt" aria-hidden="true"></i> ویرایش</a>
                        <a :class="'dropdown-item delete-post post-id-'+post.id" href="#" @click.prevent="showRemoveModal(post.id)">
                            <div class="remove-post-modal">
                                <span>آیا از حذف این مورد اطمینان دارید؟</span>
                                <div class="alert-buttons">
                                    <button class="btn-danger btn" @click="removePost(post.id)">بله</button>
                                    <button class="btn-default btn" @click.prevent.stop="cancelRemove(post.id)">خیر</button>
                                </div>
                            </div>
                            <i class="uil-trash-alt" aria-hidden="true"></i>
                            حذف
                        </a>
                    </div>
                </dropdown-menu>
            </div>
        </div>
    </div>
    <div class="tgju-widget-content profile-post-content" v-html="post.content" style="padding-right: 71px !important;"></div>


    <slot v-if="post.attachments && post.attachments[0]">

        <router-link class="post-technical" :to="{ name: 'ViewTechnical' , params: {id: technicals[post.attachments[0]['technical_id']].technical.id }}">
            <div class="post-technical-img" :style="technical_images($helpers.api_url() + $helpers.json_pars(technicals[post.attachments[0]['technical_id']].attachments)[0].name)">
                <div class="post-technical-img-tags-row">
                    <div class="post-technical-img-tags-date"><span>{{ toRelative(technicals[post.attachments[0]['technical_id']].created_at) }}</span></div>
                    <div class="post-technical-img-tags-flex"></div>
                    <div class="post-technical-img-tags-time">
                        <span>{{ technicals[post.attachments[0]['technical_id']].technical.market_title }}</span>
                    </div>
                    <div :class="'post-technical-img-tags-time bg-'+ technicals[post.attachments[0]['technical_id']].technical.time_duration">
                        <span v-if="technicals[post.attachments[0]['technical_id']].technical.time_duration == 'shortterm'">میان‌مدت <i class="uil uil-arrow-down-left"></i></span>
                        <span v-if="technicals[post.attachments[0]['technical_id']].technical.time_duration == 'longterm'">بلند‌مدت <i class="uil uil-arrow-up-left"></i></span>
                        <span v-if="technicals[post.attachments[0]['technical_id']].technical.time_duration == 'midterm'">میان‌مدت <i class="uil uil-minus"></i></span>
                    </div>
                </div>
            </div>
            <div class="post-technical-content">
                    <h2>{{ technicals[post.attachments[0]['technical_id']].title }}</h2>
                    <p>{{ technicals[post.attachments[0]['technical_id']].content }}</p>
                    <div class="post-technical-content-row">
                        <div class="post-technical-content-info">
                            <div class="post-technical-content-user">
                            <!-- <div class="post-technical-content-user-avatar" :style="'background-image:url('+$helpers.isJson(technicals[post.attachments[0]['technical_id']].user.avatar) ? ($helpers.api_url() + $helpers.json_pars(technicals[post.attachments[0]['technical_id']].user.avatar).name) : 'https://static.tgju.org/views/default/images/tgju-user-avatar.png' )"></div> -->
                                <div class="post-technical-content-user-content">
                                    <h3>{{technicals[post.attachments[0]['technical_id']].user.firstname +' '+ technicals[post.attachments[0]['technical_id']].user.lastname}}</h3>
                                    <span>تحلیل‌گر</span>
                                </div>
                            </div>
                        </div>
                        <div class="post-technical-content-icon">
                            <i class="uil uil-comment-alt-lines post-btn-icon pointer"></i>
                            <span>{{technicals[post.attachments[0]['technical_id']].comments}}</span>
                        </div>

                    </div>

                </div>
        </router-link>

        <!-- {{ technicals[post.attachments[0]['technical_id']] }} -->

    </slot>




    <div class="post-btn-row comment-btn-row">
        <div class="post-btn-item post-report-btn send-comment-btn" @click.prevent="report = !report">
            <div class="remove-post-modal post-report-modal" @click.stop style="display: block; padding: 0 !important;width: 230px !important;left: -95px !important;" v-show="report">
                <textarea rows="10" cols="20" v-model="reportContent"  style="width: 100%;padding: 10px;border: 0 !important;" placeholder="گزارش تخلف را بنویسید..."></textarea>
                <div style="color: red;" v-show="reportWarn">*متن تخلف خالی می باشد</div><br>
                <div class="alert-buttons">
                    <button class="btn-danger btn" @click="sendReport(post)">ثبت تخلف</button>
                    <button class="btn-default btn" @click="report = !report; reportContent = ''; reportWarn = false">انصراف</button>
                </div>
            </div>
            <i class="uil uil-exclamation-octagon post-btn-icon"></i>
        </div>
        <div class="post-btn-item" v-tooltip="'لایک'" v-if="$parent.user && $parent.$options.name == 'TgjuPost'">
            <i class="uil uil-thumbs-up post-btn-icon pointer" style="color: #db332f;" aria-hidden="true" v-if="post.liked" @click="likePost(post.id)"></i>
            <i class="uil uil-thumbs-up post-btn-icon pointer" aria-hidden="true" v-else @click="likePost(post.id)"></i>
            <span class="post-btn-count">{{ post.likes }}</span>
        </div>

        <!-- <div class="post-btn-item" v-tooltip="'دیدگاه‌ها'" v-if="$parent.$options.name == 'TgjuPost'" @click="toggleComments">
            <i class="uil uil-comment-alt-lines post-btn-icon pointer" aria-hidden="true" @click="setNewReplyForComment(post)"></i>
            <span class="post-btn-count">{{ post.comments_count }}</span>
        </div> -->
        
        <div class="post-btn-item send-comment-btn" v-tooltip="'ارسال پاسخ'" v-if="$parent.$options.name == 'TgjuPost'"  @click="setNewReplyForComment(post)">
            <i class="uil uil-comment-alt-lines post-btn-icon pointer"></i>
            ارسال پاسخ <div class="comment-r-badge">{{ post.comments_count }}</div>
        </div>

    </div>
    <div class="re-comments" v-if="showComments">
        <!-- فرم ارسال توییت -->
        <PostSendComment v-show="user && (newPost.parent_id || newPost.id)" :newPost="newPost" :id="'post-send-comment-' + post.id"></PostSendComment>
        <CommentItem :data="openComments(post.id)" :parent_comments="post.comments" :key="commentsRefreshEvent"></CommentItem>
    </div>
</div>
</template>

<script>
// این کامپوننت آیتم هایی که در نظرات نشان داده می شود است 
// یعنی هر آیتم یکی از این کامپونتت ها هستش
import moment from "moment-jalaali";
import DropdownMenu from '@innologica/vue-dropdown-menu';
import CommentItem from '@/components/Modals/TgjuPostComments.vue';
import PostSendComment from '@/components/TgjuPosts/PostSend.vue'
import { offset } from 'highcharts';

// import PostItem from '@/components/Posts/PostItem.vue'

export default {
    name: 'TgjuPostItem',
    components: {
        'dropdown-menu': DropdownMenu,
        CommentItem,
        PostSendComment
        // PostItem
    },
    props: ['post', 'technicals', 'subject', 'title'],
    data: function () {
        return {
            report: false,
            showComments: true,
            reportContent: '',
            reportWarn: false,
            newPost: {
                content: '',
                type: null,
                subject: null,
                attachments: [],
                parent_id: null,
                parent: null,
            },
            commentsRefreshEvent: 0,
            user: this.$helpers.userSync(),
            totalTimer: 10,
        }
    },
    computed: {
        posts: {
            get() {
                return this.$parent.posts.data;
            },
            set(value) {
                this.$parent.posts.data = value;
            }
        },
    },
    watch: {
        post: {
            handler: function (newValue, oldValue) {
                if (Object.keys(newValue).length && (typeof oldValue == 'undefined' || !Object.keys(oldValue).length)) {
                    this.showMenu = false;
                    this.showShareMenu = false;

                    // TODO: خط زیر باید کامنت بشه تا هر کاربری بتونه عکس های پست ها رو ببینه
                    // this.post.content = this.post.content.replaceAll(/<br><img class="post-img".*?>/gmi, '');

                    // جایگزین ارور بالا به دلیل پشتیبانی نکردن بعضی از مرورگرها ارور میده
                    // this.post.content = this.post.content.replace(/<br><img class="post-img".*?>/gmi, '');
                    // if (this.post.attachments.length) {
                    //     this.post.content += `<br><img class="post-img" src="${this.$helpers.api_url() + this.post.attachments[0].name}">`;
                    // }
                }
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        // گزارش تخلف
        sendReport(post) {
            if (this.reportContent.trim().length === 0) {
                this.reportWarn = true;
                return;
            }
            this.$helpers.makeRequest('POST', '/tgju-post/report' , { 
                content : this.reportContent,
                post_id : post.id,
            }).then(api_response => {
                this.$helpers.api_alert(api_response);
                if (api_response.status == 200) {
                    this.$swal.fire({
                            icon: api_response.data.response.status,
                            title: api_response.data.response.message,
                            showConfirmButton: !1,
                            timer: 2000
                        });
                } else if (api_response.status == 404) {
                        this.$swal.fire({
                            icon: "error",
                            title: 'مشکلی در ارتباط با سرور رخ داده است.',
                            showConfirmButton: !1,
                            timer: 2000
                        });
                }
            });
            this.report = false;
            this.reportContent = '';
            this.reportWarn = false;
        },
         // آواتار یک شاخص را پیدا می کند
        technical_images(url) {
            return  {
                'position' : 'relative',
                'background-image': 'url('+url+')',
            }
        },
        hiddenTimer(created_at, timer) {
            let currentDate = moment().format();
            let minutesDiff = moment(currentDate).diff(created_at, 'minutes');
            return (minutesDiff > timer) ? false : true;
        },
        link_target() {
            return window === window.parent ? '' : '_blank';
        },
        toggleComments() {
            this.showComments = !this.showComments
        },
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت حذف یک نظر از سرور استفاده میشود
        removePost(id) {
            let index = this.$helpers.getIndex(this.posts, id);
            this.$helpers.makeRequest('DELETE', '/tgju-post/delete/' + id).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this.posts.splice(index, 1);
                }
            });

            // this.$helpers.confirm('هشدار حذف!', {
            //     message: 'آیا از حذف این مورد اطمینان دارید؟',
            // }).then((confirmed) => {
            //     if (confirmed) {
            //         this.$helpers.makeRequest('DELETE', '/tgju-post/delete/' + id).then(api_response => {
            //             this.$helpers.api_alert(api_response);

            //             if (api_response.status == 200) {
            //                 this.posts.splice(index, 1);
            //             }
            //         });
            //     }
            // });
        },

        showRemoveModal(id){
            $('.post-id-'+id).addClass('active-remove-post-modal');
        },
        cancelRemove(id){
            $('.post-id-'+id).removeClass('active-remove-post-modal');
        },
        // این متد وظیفه درخواست لایک و آنلایک را برای یک نظر انجام میدهد
        likePost(id) {
            let index = this.$helpers.getIndex(this.posts, id);
            let post = this.posts[index];

            let api_data = {
                action: post.liked ? 'unlike' : 'like'
            };

            this.$helpers.makeRequest('GET', '/tgju-post/like/' + id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    post.liked = !post.liked;
                    post.likes = api_response.data.response.likes;
                }
            });
        },
        // این متد وظیفه اسکرول و نمایش  تغییر یک نظر را بر عهده دارد
        goEditPost(id) {
            let index = this.$helpers.getIndex(this.posts, id);
            let post = this.posts[index];

            this.$parent.newPost = {
                id: post.id,
                subject: this.subject,
                content: post.content.replaceAll(/<br><img class="post-img".*?>/gmi, ''),
                type: 'tgju-post',
                attachments: post.attachments,
            };

            this.$parent.setCaretToEnd();
            this.$parent.scrollTo('body');
        },

        // این متد برای نمایش کامنت های یک نظر استفاده میشود
        openComments(id) {
            let index = this.$helpers.getIndex(this.posts, id);
            let post = this.posts[index];
            // let callbackEvent = this._uid;
            return {
                posts: this.posts,
                post,
                comments: post.comments,
                subject: this.subject
            };
        },
        // این متد برای اسکرول به باکس درج کامنت استفاده میشود
        setReply(data) {
            this.newPost = data;
            this.newPost.subject = this.subject;

            // this.$scrollTo('#post-send-comment-' + this.post.id, 500, {
            //     easing: 'ease-in',
            //     lazy: false,
            //     offset: -160,
            //     force: true,
            // });
        },
        // این متد برای اسکرول به باکس ویرایش کامنت استفاده میشود
        editComment(comment) {
            this.newPost = {
                id: comment.id,
                subject: this.subject,
                content: comment.content,
                type: 'tgju-comment',
                attachments: comment.attachments,
            };

            // this.$scrollTo('#post-send-comment-' + this.post.id, 500, {
            //     easing: 'ease-in',
            //     lazy: false,
            //     offset: -160,
            //     force: true,
            // });
        },
        // این متد برای آماده سازی اسکرول و. ایجاد یک ساب کامنت جدید استفاده میشود
        setNewReplyForComment(post) {
            this.newPost = {
                parent_id: post.id,
                subject: this.subject,
                parent: post,
                content: `@${post.user.username}`,
                type: 'tgju-comment',
                attachments: []
            };

            // this.$scrollTo('#post-send-comment-' + this.post.id, 500, {
            //     easing: 'ease-in',
            //     lazy: false,
            //     offset: -160,
            //     force: true,
            // });
        },
        // این متد برای ریست کردن باکس کامنت می باشد
        resetReply() {
            this.newPost = {
                content: '',
                type: null,
                subject: null,
                attachments: [],
                parent_id: null,
                parent: null,
            };
        },
    },
}
</script>
